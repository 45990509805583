<template>
  <div class="notice-detail">
    <div class="new_title">
      <h1>{{ $t("site-control-menu-notice") }}</h1>
    </div>
    <div class="notice-writer">
      <div class="writer-info">
        <span>작성자</span>
        <span>{{this.postData!=null && this.postData.sendUserName != null ? this.postData.sendUserName : ' - ' }}</span>
      </div>
      <div class="writer-info">
        <span>작성 날짜</span>
        <span>{{this.postData!=null && this.postData.registerDate != null ? moment(this.postData.registerDate).format("YYYY.MM.DD HH:mm:ss")  : ' - ' }}</span>
      </div>
    </div>
    <div class="notice-write-content">
      <div v-if="postData.isImage==true && postData.deletedDate == null" class="notice-write-img">
        <img :src="getNoticeImageUrl(postData)"/>
      </div>
      <h2 v-html="formattedText"></h2>
      <div class="notice-writer-btn">
        <button @click="cancle">목록으로 가기</button>
        <button @click="handleDelete" v-if="isUserType == 'Admin'">내용 삭제</button>
      </div>
    </div>
  </div>
</template>

<style>
.notice-detail{margin: 0 30px;}
.notice-writer{background: #fff;padding: 15px;border-radius: 5px;display: flex;margin-bottom: 20px;}
.writer-info{margin: 0 5px;}
.writer-info > span:nth-child(1){padding: 3px 20px;background: #eee;border-radius: 5px;margin-left: 0;}
.writer-info > span{font-size: 1.2rem;margin-left: 10px}
.notice-write-content{background: #fff;padding: 15px;}
.notice-write-content > h2{margin-top: 30px;height: 250px;max-height: 250px;word-wrap: break-word;overflow-y: scroll;}
.notice-write-img{height: 300px;}
.notice-write-img > img{height: 100%;}
.notice-writer-btn{padding: 10px 0;border-top: 1px solid #ddd;text-align: right;}
.notice-writer-btn > button{border: 1px solid #ddd;border-radius: 5px;color: #969696;padding: 0 20px;margin: 0 5px;height: 40px;font-weight: 400;}
.notice-writer-btn > button:nth-child(1){border: 1px solid #0080FF;color: #ffffff;background: #0080FF;}
.notice-writer-btn > button:nth-child(1):hover{border: 1px solid #0e73c4;color: #fff;background: #0e73c4;}
.notice-writer-btn > button:nth-child(2):hover{border: 1px solid #ff5656;color: #ff5656;}

.noticeActive{background: #0080FF;}
</style>
<script>
// import SendList from "@/components/pop/SendList";
// import SendResult from "@/components/pop/SendResult";
import moment from "moment";
import { baseUrl, baseApiVersion } from "@/config/env";
import {  deleteNotice, getNoticeImageUrl  } from "@/api/event";
import { ko } from "vuejs-datepicker/dist/locale";
export default {
  // components: { SendList, SendResult },
  name: "Home",
  data() {
    return {
      baseUrl: baseUrl,
      baseApiVersion : baseApiVersion,
      moment: moment,
      total: 0,
      size: 10,
      currentPage: 0,
      notice: "",
      id: "",
      noticeList: [],
      file: "",
      img: "",

      ko: ko,

      //검색 조건
      startYmd: null,
      endYmd: null,
      keywordType: "all",
      statuses: null,
      keyword: null,

      isUserType:null,
      isNoticeDetail:false,
      noticeNum:null,
      postData: {
        noticeContent: null
      }
    };
  },
  created() {
    this.id = this.$route.params.eventId;
    this.index =  this.$route.params.index;
    this.postData = this.$route.params.data;

    this.isUserType = localStorage.getItem("userType");
    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 8 });
  },
  mounted() {
  
  },
  computed: {
    formattedText() {
      if (this.postData && this.postData.noticeContent) {
        return this.nl2br(this.postData.noticeContent);
      }
      return '내용이 없다';
    }
  },
  methods: {
    //공지 이미지 팝업 생성(크게보기)
    popImg(data){
      var popX = window.innerWidth/2;

      var imgUrl = getNoticeImageUrl(data.accountId, data.eventId, data.index);
      var img1 = new Image();

      img1.src = imgUrl;

      img1.onload = function() {
        document.body.appendChild(img1);
        var imgWidth = img1.clientWidth;
        var imgHeight = img1.clientHeight;

        popX = popX-(imgWidth/2);

        var popSize = "width="+ imgWidth+",height="+imgHeight+",top=250,left="+popX+", scrollbars=no,";

        var imgWin = window.open(imgUrl ,"", popSize);
        imgWin.document.write("<body style='margin:0;'>")
        imgWin.document.write("<img src="+imgUrl+"/>");
      }
    },
    
    getNoticeImageUrl(data){

      return getNoticeImageUrl(data.accountId, data.eventId, data.index);
    },
    
    nl2br(str) {
      return str.replace(/\n/g, '<br>');
    },

    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },

    cancle(){
        this.$router.push('Notice')
    },
    getNotice(index){
      index
      //noticeController에 단일건 조회 만든후 부르기 
    },
    async handleDelete(){
      let result = confirm(this.$t("notice-delete-confirm-text"));
      if (result == false) {
        return;
      }

      deleteNotice(this.index, this.id).then((res) => {
        if (res.data.result == 0) {
          alert("삭제 성공")
          this.$router.push('Notice')
        }
      });
    },
  },
};
</script>
